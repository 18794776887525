import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'
import HomeView from '@/views/HomeView.vue'
import SignInView from '@/views/auth/SignInView.vue'
//import ResetPasswordView from '@/views/auth/ResetPasswordView.vue'
//import NewPasswordView from '@/views/auth/NewPasswordView.vue'
import MyProfileView from '@/views/profile/MyProfileView.vue'
import MyCompanyView from '@/views/profile/MyCompanyView.vue'
import MyCompanyCompaniesTransactionHistoryView from '@/views/profile/MyCompanyCompaniesTransactionHistoryView.vue'
import MyCompanyRequisitesView from '@/views/profile/MyCompanyRequisitesView.vue'
import MyCompanyChecksView from '@/views/profile/MyCompanyChecksView.vue'
import MyCompanyServiceProviderView from '@/views/profile/MyCompanyServiceProviderView.vue'
import AdministrationCompaniesView from '@/views/administration/CompaniesView.vue'
import AdministrationCompaniesShowView from '@/views/administration/CompaniesShowView.vue'
import AdministrationCompaniesUsersView from '@/views/administration/CompaniesUsersView.vue'
import AdministrationCompaniesChecksView from '@/views/administration/CompaniesChecksView.vue'
import AdministrationCompaniesTransactionHistoryView from '@/views/administration/CompaniesTransactionHistoryView.vue'
import AdministrationCompaniesRequisitesView from '@/views/administration/CompaniesRequisitesView.vue'
import AdministrationCompaniesServiceProviderView from '@/views/administration/CompaniesServiceProviderView.vue'
import AdministrationUsersView from '@/views/administration/UsersView.vue'
import AdministrationCompaniesTariffDefaultView from '@/views/administration/CompaniesTariffDefaultView.vue'
import CompaniesTariffView from '@/views/administration/CompaniesTariffView.vue'
import PixelsView from '@/views/sources/PixelsView.vue'
import PixelsShowView from '@/views/sources/PixelsShowView.vue'
import PixelsConfigView from '@/views/sources/PixelsConfigView.vue'
import ApiKeysView from '@/views/sources/ApiKeysView.vue'
import ApiKeyDocumentationView from '@/views/sources/ApiKeyDocumentationView.vue'
import ProfilesView from '@/views/clients/ProfilesView.vue'
import CustomFieldsView from '@/views/clients/CustomFieldsView.vue'
import ProfilesShowView from '@/views/clients/ProfilesShowView.vue'
import SegmentsView from '@/views/clients/SegmentsView.vue'
import SegmentsShowProfilesView from '@/views/clients/SegmentsShowProfilesView'
import InsightsView from '@/views/clients/InsightsView.vue'
import InsightsShowProfilesView from '@/views/clients/InsightsShowProfilesView.vue'
import LookAlikesView from '@/views/clients/LookAlikesView.vue'
import LookAlikesProfilesView from '@/views/clients/LookAlikesProfilesView.vue'
import ExportView from '@/views/clients/ExportView.vue'
import ImportProfilesView from '@/views/clients/ImportProfilesView.vue'
import ImportProfilesCreateView from '@/views/clients/ImportProfilesCreateView.vue'
import StatisticsView from '@/views/statistics/StatisticsView.vue'
import CampaignsView from '@/views/newsletter/CampaignsView.vue'
import SmsCampaignsView from '@/views/newsletter/SmsCampaignsView.vue'
import SmsCampaignsCreateView from '@/views/newsletter/SmsCampaignsCreateView.vue'
import SmsCampaignsEditView from '@/views/newsletter/SmsCampaignsEditView.vue'
import SmsCampaignsShowView from '@/views/newsletter/SmsCampaignsShowView.vue'
import SmsCampaignsAddresseesView from '@/views/newsletter/SmsCampaignsAddresseesView.vue'
import SmsCampaignsStatisticsView from '@/views/newsletter/SmsCampaignsStatisticsView.vue'
import SmsCampaignsCalendarView from '@/views/newsletter/SmsCampaignsCalendarView.vue'
import SmsCampaignsTemplatesView from '@/views/newsletter/SmsCampaignsTemplatesView.vue'
import SmsChannelsView from '@/views/newsletter/SmsChannelsView.vue'
import EmailCampaignsView from '@/views/newsletter/EmailCampaignsView.vue'
import EmailCampaignsCreateView from '@/views/newsletter/EmailCampaignsCreateView.vue'
import EmailCampaignsShowView from '@/views/newsletter/EmailCampaignsShowView.vue'
import EmailCampaignsShowAddresseesView from '@/views/newsletter/EmailCampaignsShowAddresseesView.vue'
import EmailCampaignsShowStatisticsView from '@/views/newsletter/EmailCampaignsShowStatisticsView.vue'
import EmailCampaignsCalendarView from '@/views/newsletter/EmailCampaignsCalendarView'
import EmailCampaignsShowTemplateView from '@/views/newsletter/EmailCampaignsShowTemplateView.vue'
import EmailCampaignsEditView from '@/views/newsletter/EmailCampaignsEditView.vue'
import EmailCampaignsTemplatesView from '@/views/newsletter/EmailCampaignsTemplatesView.vue'
import EmailCampaignsSettingsView from '@/views/newsletter/EmailCampaignsSettingsView.vue'
import EmailCampaignsTemplatesCreateView from '@/views/newsletter/EmailCampaignsTemplatesCreateView.vue'
import EmailCampaignsTemplatesEditView from '@/views/newsletter/EmailCampaignsTemplatesEditView.vue'
import EmailCampaignsTemplatesShowView from '@/views/newsletter/EmailCampaignsTemplatesShowView.vue'
import SmsPushCampaignsView from '@/views/newsletter/SmsPushCampaignsView.vue'
import SmsPushCampaignsCreateView from '@/views/newsletter/SmsPushCampaignsCreateView.vue'
import SmsPushCampaignsShowView from '@/views/newsletter/SmsPushCampaignsShowView.vue'
import SmsPushCampaignsEditView from '@/views/newsletter/SmsPushCampaignsEditView.vue'
import SmsPushCampaignsAdresseesView from '@/views/newsletter/SmsPushCampaignsAdresseesView.vue'
import SmsPushCampaignsStatisticsView from '@/views/newsletter/SmsPushCampaignsStatisticsView.vue'
import SmsPushCampaignsTemplatesView from '@/views/newsletter/SmsPushCampaignsTemplatesView.vue'
import SmsPushChannelsView from '@/views/newsletter/SmsPushChannelsView.vue'
import SmsPushCampaignsCalendarView from '@/views/newsletter/SmsPushCampaignsCalendarView.vue'
import ScenariosCampaignsView from '@/views/newsletter/ScenariosCampaignsView.vue'
import ScenariosCampaignsCreateView from '@/views/newsletter/ScenariosCampaignsCreateView.vue'
import ScenariosCampaignsTemplatesView from '@/views/newsletter/ScenariosCampaignsTemplatesView.vue'
import ScenariosCampaignsTemplatesEditView from '@/views/newsletter/ScenariosCampaignsTemplatesEditView.vue'
import ScenariosCampaignsShowView from '@/views/newsletter/ScenariosCampaignsShowView.vue'
import ScenariosCampaignsShowSmsView from '@/views/newsletter/ScenariosCampaignsShowSmsView.vue'
import ScenariosCampaignsConstructorView from '@/views/newsletter/ScenariosCampaignsConstructorView.vue'
import ScenariosCampaignsSmsAdressees from '@/views/newsletter/ScenariosCampaignsSmsAdressees.vue'
import ScenariosCampaignsShowEmailView from '@/views/newsletter/ScenariosCampaignsShowEmailView.vue'
import ScenariosCampaignsEmailAdressees from '@/views/newsletter/ScenariosCampaignsEmailAdressees.vue'
import ScenariosCampaignsShowPushView from '@/views/newsletter/ScenariosCampaignsShowPushView.vue'
import ScenariosCampaignsPushAdresseesView from '@/views/newsletter/ScenariosCampaignsPushAdresseesView.vue'
import ScenariosCampaignsShowErrorsView from '@/views/newsletter/ScenariosCampaignsShowErrorsView.vue'
import ScenariosCampaignsShowViberView from '@/views/newsletter/ScenariosCampaignsShowViberView.vue'
import ScenariosCampaignsViberAdresseesView from '@/views/newsletter/ScenariosCampaignsViberAdresseesView.vue'
import ViberCampaignsView from '@/views/newsletter/ViberCampaignsView.vue'
import ViberCampaignsCreateView from '@/views/newsletter/ViberCampaignsCreateView.vue'
import ViberCampaignsShowView from '@/views/newsletter/ViberCampaignsShowView.vue'
import ViberCampaignsAdresseesView from '@/views/newsletter/ViberCampaignsAdresseesView.vue'
import ViberCampaignsEditView from '@/views/newsletter/ViberCampaignsEditView.vue'
import ViberCampaignsCalendarView from '@/views/newsletter/ViberCampaignsCalendarView.vue'
import ViberCampaignsTemplatesView from '@/views/newsletter/ViberCampaignsTemplatesView.vue'
import ViberCampaignsConfigsView from '@/views/newsletter/ViberCampaignsConfigsView.vue'
import DocumentationView from '@/views/documentation/DocumentationView.vue'
import OrdersView from '@/views/sales/OrdersView.vue'
import SalesSettingsView from '@/views/sales/SalesSettingsView.vue'
import ProductsView from '@/views/sales/ProductsView.vue'
import OrdersShowView from '@/views/sales/OrdersShowView.vue'
import ProductsShowView from '@/views/sales/ProductsShowView.vue'
import ProductsEditView from '@/views/sales/ProductsEditView.vue'

function checkAccess(to, from, next, access) {
  const user = useUserStore()
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (user.isAuthenticated) {
      user.checkAuthenticated()
      let isAccess = false
      if (access.includes('|')) {
        let accesses = access.split('|')
        for (let currentAccess of accesses) {
          isAccess = isAccess || user.can(currentAccess)
        }
      } else if (access != '') {
        isAccess = user.can(access)
      } else {
        isAccess = true
      }
      
      if (isAccess) {
        next() 
      } else {
        next('/statistics')
      }
    } else {
      next('/sign_in')
    }
  } else {
    if (user.isAuthenticated) {
      next('/')
    } else {
      next() 
    }
  }
}

function check404(to, from, next) {
  const user = useUserStore()
  if (user.isAuthenticated) {
    user.checkAuthenticated()
    next('/')
  } else {
    next('/sign_in')
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {requiresAuth: true, title: 'CXDP'},
    beforeEnter: (to, from, next) => {
      next('/statistics')
      document.title = to.meta.title
    }
  },
  {
    path: '/sign_in',
    name: 'sign_in',
    component: SignInView,
    meta: { guest: true, title: 'Авторизация' },
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
      document.title = to.meta.title
    }
  },
  /*{
    path: '/reset_password',
    name: 'reset_password',
    component: ResetPasswordView,
    meta: { guest: true },
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
    }
  },
  {
    path: '/new_password',
    name: 'new_password',
    component: NewPasswordView,
    meta: { guest: true },
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
    }
  },*/
  {
    path: '/current_user',
    name: 'current_user',
    component: MyProfileView,
    meta: {requiresAuth: true, title: 'Мой профиль'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
      document.title = to.meta.title
    }
  },
  {
    path: '/current_user/profile',
    name: 'current_user_profile',
    component: MyProfileView,
    meta: {requiresAuth: true, title: 'Мой профиль'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
      document.title = to.meta.title
    }
  },
  {
    path: '/current_user/company',
    name: 'current_user_company',
    component: MyCompanyView,
    meta: {requiresAuth: true, title: 'Моя компания'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.show')
      document.title = to.meta.title
    }
  },
  {
    path: '/current_user/company/transactions',
    name: 'current_user_company_transactions',
    component: MyCompanyCompaniesTransactionHistoryView,
    meta: {requiresAuth: true, title: 'Транзакции'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.transactions.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/current_user/company/requisites',
    name: 'current_user_company_requisites',
    component: MyCompanyRequisitesView,
    meta: {requiresAuth: true, title: 'Реквизиты'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.requisites.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/current_user/company/checks',
    name: 'current_user_company_checks',
    component: MyCompanyChecksView,
    meta: {requiresAuth: true, title: 'Счета'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.invoices.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/current_user/company/service_provider',
    name: 'current_user_company_service_provider',
    component: MyCompanyServiceProviderView,
    meta: {requiresAuth: true, title: 'Сервис-провайдер'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.show')
      document.title = to.meta.title
    }
  },
  {
    path: '/administration',
    name: 'administration',
    component: AdministrationCompaniesView,
    meta: {requiresAuth: true, title: 'Компании'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'companies.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/administration/companies',
    name: 'administration_companies',
    component: AdministrationCompaniesView,
    meta: {requiresAuth: true, title: 'Компании'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'companies.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/administration/companies/:id/show',
    name: 'administration_companies_show',
    component: AdministrationCompaniesShowView,
    meta: {requiresAuth: true, title: 'Компания'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `companies.${to.params.id}.edit`)
      document.title = to.meta.title
    }
  },
  {
    path: '/administration/companies/:id/users',
    name: 'administration_companies_users',
    component: AdministrationCompaniesUsersView,
    meta: {requiresAuth: true, title: 'Пользователи'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `companies.${to.params.id}.users.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/administration/companies/:id/checks',
    name: 'administration_companies_checks',
    component: AdministrationCompaniesChecksView,
    meta: {requiresAuth: true, title: 'Счета'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `companies.${to.params.id}.invoices.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/administration/companies/:id/transactions',
    name: 'administration_companies_transactions',
    component: AdministrationCompaniesTransactionHistoryView,
    meta: {requiresAuth: true, title: 'Транзакции'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `companies.${to.params.id}.transactions.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/administration/companies/:id/requisites',
    name: 'administration_companies_requisites',
    component: AdministrationCompaniesRequisitesView,
    meta: {requiresAuth: true, title: 'Реквизиты'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `companies.${to.params.id}.requisites.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/administration/companies/:id/tariff',
    name: 'administration_companies_tariff',
    component: CompaniesTariffView,
    meta: {requiresAuth: true, title: 'Тариф'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `companies.${to.params.id}.edit`)
      document.title = to.meta.title
    }
  },
  {
    path: '/administration/companies/:id/service_provider',
    name: 'administration_companies_service_provider',
    component: AdministrationCompaniesServiceProviderView,
    meta: {requiresAuth: true, title: 'Сервис-провайдер'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `companies.${to.params.id}.edit`)
      document.title = to.meta.title
    }
  },
  {
    path: '/administration/users',
    name: 'administration_users',
    component: AdministrationUsersView,
    meta: {requiresAuth: true, title: 'Пользователи'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'users.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/administration/tariff',
    name: 'administration_tariff',
    component: AdministrationCompaniesTariffDefaultView,
    meta: {requiresAuth: true, title: 'Тарифы'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'companies.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/sources',
    name: 'sources',
    component: PixelsView,
    meta: {requiresAuth: true, title: 'Пиксели'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'pixels.index|my_company.pixels.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/sources/pixels',
    name: 'sources_pixels',
    component: PixelsView,
    meta: {requiresAuth: true, title: 'Пиксели'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'pixels.index|my_company.pixels.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/sources/pixels/:id',
    name: 'sources_pixels_show',
    component: PixelsShowView,
    meta: {requiresAuth: true, title: 'Пиксель'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `pixels.${to.params.id}.edit|my_company.pixels.${to.params.id}.edit|pixels.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/sources/config_pixels',
    name: 'sources_config_pixels',
    component: PixelsConfigView,
    meta: {requiresAuth: true, title: 'Настройка событий'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'predefined_pixel_events.index|my_company.predefined_pixel_events.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/sources/api_keys',
    name: 'sources_api_keys',
    component: ApiKeysView,
    meta: {requiresAuth: true, title: 'API'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'api_keys.index|my_company.api_keys.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/sources/api_keys/documentation',
    name: 'sources_api_keys_documentation',
    component: ApiKeyDocumentationView,
    meta: {requiresAuth: true, title: 'Документация'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'api_keys.index|my_company.api_keys.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/clients',
    name: 'clients',
    component: ProfilesView,
    meta: {requiresAuth: true, title: 'Профили'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.cdp_profiles.index|cdp_profiles.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/clients/profiles',
    name: 'clients_profiles',
    component: ProfilesView,
    meta: {requiresAuth: true, title: 'Профили'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.cdp_profiles.index|cdp_profiles.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/clients/profiles/:id',
    name: 'clients_profiles_show',
    component: ProfilesShowView,
    meta: {requiresAuth: true, title: 'Профиль'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.cdp_profiles.index|cdp_profiles.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/clients/segments',
    name: 'clients_segments',
    component: SegmentsView,
    meta: {requiresAuth: true, title: 'Сегмент'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.cdp_profiles.cdp_segments.index|cdp_segments.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/clients/segments/:id/profiles',
    name: 'clients_segments_profiles_show',
    component: SegmentsShowProfilesView,
    meta: {requiresAuth: true, title: 'Профили'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `my_company.cdp_segments.${to.params.id}|cdp_segments.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/clients/insights',
    name: 'clients_insights',
    component: InsightsView,
    meta: {requiresAuth: true, title: 'Инсайты'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.cdp_insights.index|cdp_insights.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/clients/insights/:id/profiles',
    name: 'clients_insights_profiles_show',
    component: InsightsShowProfilesView,
    meta: {requiresAuth: true, title: 'Профили'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `cdp_insights.${to.params.id}|cdp_insights.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/clients/lookalikes',
    name: 'clients_lookalikes',
    component: LookAlikesView,
    meta: {requiresAuth: true, title: 'Look-alike'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.cdp_profiles.cdp_segments.index|cdp_segments.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/clients/lookalikes/:id/profiles',
    name: 'clients_lookalikes_profiles',
    component: LookAlikesProfilesView,
    meta: {requiresAuth: true, title: 'Профили'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.cdp_profiles.index|cdp_profiles.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/clients/export',
    name: 'clients_export',
    component: ExportView,
    meta: {requiresAuth: true, title: 'Экспорт'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.cdp_profiles.index|cdp_profiles.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/clients/import',
    name: 'clients_import',
    component: ImportProfilesView,
    meta: {requiresAuth: true, title: 'Импорт'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.cdp_profile_imports.index|cdp_profile_imports.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/clients/import/create',
    name: 'clients_import_create',
    component: ImportProfilesCreateView,
    meta: {requiresAuth: true, title: 'Новый Импорт'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.cdp_profile_imports.index|cdp_profile_imports.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/clients/custom_fields',
    name: 'clients_custom_fields',
    component: CustomFieldsView,
    meta: {requiresAuth: true, title: 'Настройка полей профиля'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.custom_fields.index|custom_fields.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: StatisticsView,
    meta: {requiresAuth: true, title: 'Статистика'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter',
    name: 'newsletter',
    component: CampaignsView,
    meta: {requiresAuth: true, title: 'Кампании'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/sms',
    name: 'newsletter_sms',
    component: SmsCampaignsView,
    meta: {requiresAuth: true, title: 'SMS-кампании'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'sms_campaigns.index|my_company.sms_campaigns.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/sms/create',
    name: 'newsletter_sms_create',
    component: SmsCampaignsCreateView,
    meta: {requiresAuth: true, title: 'Создание SMS-кампании'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'sms_campaigns.index|my_company.sms_campaigns.create')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/sms/:id/edit',
    name: 'newsletter_sms_edit',
    component: SmsCampaignsEditView,
    meta: {requiresAuth: true, title: 'Редактирование SMS-кампании'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `sms_campaigns.${to.params.id}.edit|my_company.sms_campaigns.${to.params.id}.edit|sms_campaigns.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/sms/:id',
    name: 'newsletter_sms_show',
    component: SmsCampaignsShowView,
    meta: {requiresAuth: true, title: 'SMS-кампания'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `sms_campaigns.${to.params.id}.show|my_company.sms_campaigns.${to.params.id}.show|sms_campaigns.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/sms/:id/addressees',
    name: 'newsletter_sms_addressees',
    component: SmsCampaignsAddresseesView,
    meta: {requiresAuth: true, title: 'SMS-кампания'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `sms_campaigns.${to.params.id}.show|my_company.sms_campaigns.${to.params.id}.show|sms_campaigns.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/sms/:id/statistics',
    name: 'newsletter_sms_statistics',
    component: SmsCampaignsStatisticsView,
    meta: {requiresAuth: true, title: 'SMS-кампания'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `sms_campaigns.${to.params.id}.show|my_company.sms_campaigns.${to.params.id}.show|sms_campaigns.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/sms/calendar',
    name: 'newsletter_sms_calendar',
    component: SmsCampaignsCalendarView,
    meta: {requiresAuth: true, title: 'Календарь'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'sms_campaigns.index|my_company.sms_campaigns.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/sms/templates',
    name: 'newsletter_sms_templates',
    component: SmsCampaignsTemplatesView,
    meta: {requiresAuth: true, title: 'Шаблоны'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'sms_templates.index|my_company.sms_templates.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/sms/channels',
    name: 'newsletter_sms_channels',
    component: SmsChannelsView,
    meta: {requiresAuth: true, title: 'Имена отправителя'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'sms_sender_names.index|my_company.sms_sender_names.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/email',
    name: 'newsletter_email',
    component: EmailCampaignsView,
    meta: {requiresAuth: true, title: 'Email-кампании'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'email_campaigns.index|my_company.email_campaigns.index|email_campaigns.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/email/:id',
    name: 'newsletter_email_show',
    component: EmailCampaignsShowView,
    meta: {requiresAuth: true, title: 'Email-кампания'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `email_campaigns.${to.params.id}|my_company.email_campaigns.${to.params.id}|email_campaigns.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/email/:id/addressees',
    name: 'newsletter_email_show_addressees',
    component: EmailCampaignsShowAddresseesView,
    meta: {requiresAuth: true, title: 'Email-кампания'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `email_campaigns.${to.params.id}|my_company.email_campaigns.${to.params.id}|email_campaigns.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/email/:id/statistics',
    name: 'newsletter_email_show_statistics',
    component: EmailCampaignsShowStatisticsView,
    meta: {requiresAuth: true, title: 'Email-кампания'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `email_campaigns.${to.params.id}|my_company.email_campaigns.${to.params.id}|email_campaigns.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/email/calendar',
    name: 'newsletter_email_calendar',
    component: EmailCampaignsCalendarView,
    meta: {requiresAuth: true, title: 'Email-кампания'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'email_campaigns.index|my_company.email_campaigns.index|email_campaigns.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/email/:id/template',
    name: 'newsletter_email_show_template',
    component: EmailCampaignsShowTemplateView ,
    meta: {requiresAuth: true, title: 'Календарь'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `email_campaigns.${to.params.id}|my_company.email_campaigns.${to.params.id}|email_campaigns.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/email/:id/edit',
    name: 'newsletter_email_edit',
    component: EmailCampaignsEditView,
    meta: {requiresAuth: true, title: 'Email-кампания'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `email_campaigns.${to.params.id}.edit|my_company.email_campaigns.${to.params.id}.edit|email_campaigns.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/email/create',
    name: 'newsletter_email_create',
    component: EmailCampaignsCreateView,
    meta: {requiresAuth: true, title: 'Создание Email-кампанию'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'email_campaigns.create|my_company.email_campaigns.create|email_campaigns.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/email/templates',
    name: 'newsletter_email_templates',
    component: EmailCampaignsTemplatesView,
    meta: {requiresAuth: true, title: 'Шаблоны'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/email/templates/create',
    name: 'newsletter_email_templates_create',
    component: EmailCampaignsTemplatesCreateView,
    meta: {requiresAuth: true, title: 'Создание шаблона'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/email/templates/:id/edit',
    name: 'newsletter_email_templates_edit',
    component: EmailCampaignsTemplatesEditView,
    meta: {requiresAuth: true, title: 'Редактирование шаблона'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/email/templates/:id',
    name: 'newsletter_email_templates_show',
    component: EmailCampaignsTemplatesShowView,
    meta: {requiresAuth: true, title: 'Просмотр шаблона'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/email/settings',
    name: 'newsletter_email_settings',
    component: EmailCampaignsSettingsView,
    meta: {requiresAuth: true, title: 'Настройки'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'email_sender_names.index|my_company.email_sender_names.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/push',
    name: 'newsletter_push',
    component: SmsPushCampaignsView,
    meta: {requiresAuth: true, title: 'Mobile-push-кампании'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'mobile_push_campaigns.index|my_company.mobile_push_campaigns.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/push/create',
    name: 'newsletter_push_create',
    component: SmsPushCampaignsCreateView,
    meta: {requiresAuth: true, title: 'Создание Mobile-push-кампании'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'mobile_push_campaigns.index|my_company.mobile_push_campaigns.create')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/push/:id',
    name: 'newsletter_push_show',
    component: SmsPushCampaignsShowView,
    meta: {requiresAuth: true, title: 'Mobile-push-кампания'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `mobile_push_campaigns.${to.params.id}.show|my_company.mobile_push_campaigns.${to.params.id}.show|mobile_push_campaigns.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/push/:id/edit',
    name: 'newsletter_push_edit',
    component: SmsPushCampaignsEditView,
    meta: {requiresAuth: true, title: 'Редактирование Mobile-push-кампании'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `mobile_push_campaigns.${to.params.id}.edit|my_company.mobile_push_campaigns.${to.params.id}.edit|mobile_push_campaigns.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/push/:id/addressees',
    name: 'newsletter_push_addressees',
    component: SmsPushCampaignsAdresseesView,
    meta: {requiresAuth: true, title: 'Mobile-push-кампания'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `mobile_push_campaigns.${to.params.id}.show|my_company.mobile_push_campaigns.${to.params.id}.show|mobile_push_campaigns.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/push/:id/statistics',
    name: 'newsletter_push_statistics',
    component: SmsPushCampaignsStatisticsView,
    meta: {requiresAuth: true, title: 'Mobile-push-кампания'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `mobile_push_campaigns.${to.params.id}.show|my_company.mobile_push_campaigns.${to.params.id}.show|mobile_push_campaigns.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/push/templates',
    name: 'newsletter_push_templates',
    component: SmsPushCampaignsTemplatesView,
    meta: {requiresAuth: true, title: 'Шаблоны'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'mobile_push_templates.index|my_company.mobile_push_templates.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/push/channels',
    name: 'newsletter_push_channels',
    component: SmsPushChannelsView,
    meta: {requiresAuth: true, title: 'Мобильные приложения'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'mobile_push_apps.index|my_company.mobile_push_apps.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/push/calendar',
    name: 'newsletter_push_calendar',
    component: SmsPushCampaignsCalendarView,
    meta: {requiresAuth: true, title: 'Календарь'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'mobile_push_apps.index|my_company.mobile_push_apps.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/scenarios',
    name: 'newsletter_scenarios',
    component: ScenariosCampaignsView,
    meta: {requiresAuth: true, title: 'Сценарии'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.scenarios.index|scenarios.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/scenarios/create',
    name: 'newsletter_scenarios_create',
    component: ScenariosCampaignsCreateView,
    meta: {requiresAuth: true, title: 'Создание Сценария'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.scenarios.index|scenarios.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/scenarios/templates',
    name: 'newsletter_scenarios_templates',
    component: ScenariosCampaignsTemplatesView,
    meta: {requiresAuth: true, title: 'Шаблоны'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.scenario_templates.index|scenario_templates.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/scenarios/templates/:id/edit',
    name: 'newsletter_scenarios_templates_edit',
    component: ScenariosCampaignsTemplatesEditView,
    meta: {requiresAuth: true, title: 'Шаблон'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `scenario_templates.${to.params.id}.show|my_company.scenario_templates.${to.params.id}.show|scenario_templates.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/scenarios/:id',
    name: 'newsletter_scenarios_show',
    component: ScenariosCampaignsShowView,
    meta: {requiresAuth: true, title: 'Сценарии'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `scenarios.${to.params.id}.show|my_company.scenarios.${to.params.id}.show|scenarios.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/scenarios/:id/constructor',
    name: 'newsletter_scenarios_constructor',
    component: ScenariosCampaignsConstructorView,
    meta: {requiresAuth: true, title: 'Конструктор сценариев'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `scenario_templates.${to.params.id}.show|my_company.scenario_templates.${to.params.id}.show|scenario_templates.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/scenarios/:id/sms/:step_id',
    name: 'newsletter_scenarios_show_sms',
    component: ScenariosCampaignsShowSmsView,
    meta: {requiresAuth: true, title: 'SMS рассылка'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `scenarios.${to.params.id}.show|my_company.scenarios.${to.params.id}.show|scenarios.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/scenarios/:id/sms/adressees/:step_id',
    name: 'newsletter_scenarios_show_sms_adressees',
    component: ScenariosCampaignsSmsAdressees,
    meta: {requiresAuth: true, title: 'SMS рассылка'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `scenarios.${to.params.id}.show|my_company.scenarios.${to.params.id}.show|scenarios.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/scenarios/:id/email/:step_id',
    name: 'newsletter_scenarios_show_email',
    component: ScenariosCampaignsShowEmailView,
    meta: {requiresAuth: true, title: 'Email рассылка'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `scenarios.${to.params.id}.show|my_company.scenarios.${to.params.id}.show|scenarios.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/scenarios/:id/email/adressees/:step_id',
    name: 'newsletter_scenarios_show_email_adressees',
    component: ScenariosCampaignsEmailAdressees,
    meta: {requiresAuth: true, title: 'Email рассылка'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `scenarios.${to.params.id}.show|my_company.scenarios.${to.params.id}.show|scenarios.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/scenarios/:id/push/:step_id',
    name: 'newsletter_scenarios_show_push',
    component: ScenariosCampaignsShowPushView,
    meta: {requiresAuth: true, title: 'Push рассылка'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `scenarios.${to.params.id}.show|my_company.scenarios.${to.params.id}.show|scenarios.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/scenarios/:id/viber/:step_id',
    name: 'newsletter_scenarios_show_viber',
    component: ScenariosCampaignsShowViberView,
    meta: {requiresAuth: true, title: 'Viber кампания'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `scenarios.${to.params.id}.show|my_company.scenarios.${to.params.id}.show|scenarios.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/scenarios/:id/viber/adressees/:step_id',
    name: 'newsletter_scenarios_show_viber_adressees',
    component: ScenariosCampaignsViberAdresseesView,
    meta: {requiresAuth: true, title: 'Viber кампания'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `scenarios.${to.params.id}.show|my_company.scenarios.${to.params.id}.show|scenarios.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/scenarios/:id/push/adressees/:step_id',
    name: 'newsletter_scenarios_show_push_adressees',
    component: ScenariosCampaignsPushAdresseesView,
    meta: {requiresAuth: true, title: 'Push рассылка'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `scenarios.${to.params.id}.show|my_company.scenarios.${to.params.id}.show|scenarios.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/scenarios/:id/errors',
    name: 'newsletter_scenarios_show_error',
    component: ScenariosCampaignsShowErrorsView,
    meta: {requiresAuth: true, title: 'Ошибки сценария'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `scenarios.${to.params.id}.show|my_company.scenarios.${to.params.id}.show|scenarios.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/viber',
    name: 'newsletter_viber',
    component: ViberCampaignsView,
    meta: {requiresAuth: true, title: 'Viber-кампании'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.viber_campaigns.index|viber_campaigns.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/viber/create',
    name: 'newsletter_viber_create',
    component: ViberCampaignsCreateView,
    meta: {requiresAuth: true, title: 'Создание Viber-кампании'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.viber_campaigns.index|viber_campaigns.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/viber/:id',
    name: 'newsletter_viber_show',
    component: ViberCampaignsShowView,
    meta: {requiresAuth: true, title: 'Просмотр Viber-кампании'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `viber_campaigns.${to.params.id}.show|my_company.viber_campaigns.${to.params.id}.show|viber_campaigns.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/viber/:id/addressees',
    name: 'newsletter_viber_adressees',
    component: ViberCampaignsAdresseesView,
    meta: {requiresAuth: true, title: 'Просмотр Viber-кампании'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `viber_campaigns.${to.params.id}.show|my_company.viber_campaigns.${to.params.id}.show|viber_campaigns.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/viber/:id/edit',
    name: 'newsletter_viber_edit',
    component: ViberCampaignsEditView,
    meta: {requiresAuth: true, title: 'Редактирование Viber-кампании'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, `viber_campaigns.${to.params.id}.show|my_company.viber_campaigns.${to.params.id}.show|viber_campaigns.index`)
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/viber/calendar',
    name: 'newsletter_viber_calendar',
    component: ViberCampaignsCalendarView,
    meta: {requiresAuth: true, title: 'Календарь'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.viber_campaigns.index|viber_campaigns.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/viber/templates',
    name: 'newsletter_viber_templates',
    component: ViberCampaignsTemplatesView,
    meta: {requiresAuth: true, title: 'Шаблоны'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.viber_templates.index|viber_templates.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/newsletter/viber/configs',
    name: 'newsletter_viber_configs',
    component: ViberCampaignsConfigsView,
    meta: {requiresAuth: true, title: 'Настройки'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, 'my_company.viber_ids.index|viber_ids.index')
      document.title = to.meta.title
    }
  },
  {
    path: '/documentation',
    name: 'documentation',
    component: DocumentationView,
    meta: {requiresAuth: true, title: 'Документация'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
      document.title = to.meta.title
    }
  },
  {
    path: '/sales/orders',
    name: 'sales_oreders',
    component: OrdersView,
    meta: {requiresAuth: true, title: 'Заказы'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
      document.title = to.meta.title
    }
  },
  {
    path: '/sales/products',
    name: 'sales_products',
    component: ProductsView,
    meta: {requiresAuth: true, title: 'Товары'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
      document.title = to.meta.title
    }
  },
  {
    path: '/sales/settings',
    name: 'sales_settings',
    component: SalesSettingsView,
    meta: {requiresAuth: true, title: 'Настройки'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
      document.title = to.meta.title
    }
  },
  {
    path: '/sales/orders/:id',
    name: 'sales_oreders_show',
    component: OrdersShowView,
    meta: {requiresAuth: true, title: 'Заказы'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
      document.title = to.meta.title
    }
  },
  {
    path: '/sales/products/:id',
    name: 'sales_products_show',
    component: ProductsShowView,
    meta: {requiresAuth: true, title: 'Товары'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
      document.title = to.meta.title
    }
  },
  {
    path: '/sales/products/:id/edit',
    name: 'sales_products_edit',
    component: ProductsEditView,
    meta: {requiresAuth: true, title: 'Товары'},
    beforeEnter: (to, from, next) => {
      checkAccess(to, from, next, '')
      document.title = to.meta.title
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: HomeView,
    beforeEnter: (to, from, next) => {
      check404(to, from, next)
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router